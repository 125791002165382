// src/components/Contact/ContactSection.jsx
import React from 'react';
import { ArrowRight, Globe2 } from 'lucide-react';
import { styles } from './styles';

const ContactSection = () => {
  return (
    <div style={styles.container}>

      {/* Advertising Section */}
      <div style={styles.section}>
        <div style={styles.contentWrapper}>
          <div style={styles.iconWrapper}>
            <Globe2 size={24} style={styles.icon} />
          </div>
          
          <div style={styles.content}>
            <h3 style={styles.title}>
              Advertise With Us
            </h3>
            
            <div style={styles.alertBox}>
              <div style={styles.alertTitle}>
                Reach Your Target Market
              </div>
              <div style={styles.alertDescription}>
                Connect with home buyers and property seekers in specific countries. 
                Our platform offers targeted advertising opportunities for:
                <ul style={styles.bulletList}>
                  <li>Real Estate Agencies</li>
                  <li>Property Developers</li>
                  <li>Mortgage Providers</li>
                  <li>Relocation Services</li>
                  <li>Insurance Companies</li>
                </ul>
              </div>
            </div>

            <a 
              href="mailto:liferelocate@gmail.com.com" 
              style={styles.emailLink}
              onMouseOver={(e) => e.target.style.color = styles.emailLink['&:hover'].color}
              onMouseOut={(e) => e.target.style.color = styles.emailLink.color}
            >
              <span>Contact our advertising team at liferelocate@gmail.com</span>
              <ArrowRight size={16} />
            </a>
            
            <p style={styles.responseTime}>
              Media kit and rate card available upon request
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;