// src/components/Calculator/EmploymentTypeTooltip.jsx
import React, { useState } from 'react';
import { Info, Briefcase } from 'lucide-react';
import { styles } from './styles';

const EmploymentTypeTooltip = ({ value, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipStyle = {
    display: showTooltip ? 'block' : 'none',
    position: 'absolute',
    right: '0',
    top: '100%',
    width: '300px',
    padding: '15px',
    backgroundColor: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    zIndex: 10,
    fontSize: '14px'
  };

  return (
    <div style={styles.formGroup}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
        <label style={styles.label}>Employment Type</label>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <Info 
            size={20} 
            style={{ cursor: 'pointer', color: '#17A2B8' }}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          <div style={tooltipStyle}>
            <h4 style={{ marginTop: 0, marginBottom: '10px', color: '#17A2B8' }}>
              How Employment Type Affects Your Mortgage
            </h4>
            <div style={{ marginBottom: '10px' }}>
              <strong>Permanent Contract:</strong>
              <p>No reduction in lending capacity. Most favorable option as it provides maximum stability.</p>
              <ul style={{ marginTop: '4px', paddingLeft: '20px', color: '#64748b', fontSize: '13px' }}>
                <li>Full base multiplier (4.5x or 5.0x)</li>
                <li>Passed probation period</li>
                <li>Minimum 6 months in role</li>
              </ul>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <strong>Temporary Contract (10% reduction):</strong>
              <p>Slightly reduced lending capacity due to fixed-term nature.</p>
              <ul style={{ marginTop: '4px', paddingLeft: '20px', color: '#64748b', fontSize: '13px' }}>
                <li>90% of base multiplier</li>
                <li>Employer's intent declaration needed</li>
                <li>Contract length consideration</li>
              </ul>
            </div>
            <div>
              <strong>Self Employed (20% reduction):</strong>
              <p>Larger reduction due to income variability.</p>
              <ul style={{ marginTop: '4px', paddingLeft: '20px', color: '#64748b', fontSize: '13px' }}>
                <li>80% of base multiplier</li>
                <li>3+ years tax returns required</li>
                <li>Average income used</li>
              </ul>
            </div>
            <div style={{ marginTop: '10px', padding: '8px', backgroundColor: '#f8fafc', borderRadius: '4px', fontSize: '13px' }}>
              <strong>Note:</strong> These reductions may combine with other factors like residency duration.
            </div>
          </div>
        </div>
      </div>
      <div style={styles.inputWrapper}>
        <div style={styles.icon}>
          <Briefcase size={20} />
        </div>
        <select
          style={styles.select}
          value={value}
          onChange={onChange}
        >
          <option value="permanent">Permanent Contract</option>
          <option value="temporary">Temporary Contract</option>
          <option value="self-employed">Self Employed</option>
        </select>
      </div>
    </div>
  );
};

export default EmploymentTypeTooltip;