// src/components/Calculator/InputField.jsx
import React from 'react';
import { styles } from './styles';

const InputField = ({ label, icon: Icon, type, value, onChange, prefix, suffix, ...props }) => (
  <div style={styles.formGroup}>
    <label style={styles.label}>{label}</label>
    <div style={styles.inputWrapper}>
      <div style={styles.icon}>
        <Icon size={20} />
      </div>
      {prefix && <span style={styles.currencyPrefix}>{prefix}</span>}
      <input
        type={type}
        style={{
          ...styles.input,
          paddingLeft: prefix ? '52px' : '40px', // Adjust padding if there's a prefix
          paddingRight: suffix ? '32px' : '12px' // Adjust padding if there's a suffix
        }}
        value={value}
        onChange={onChange}
        {...props}
      />
      {suffix && <span style={styles.percentageSuffix}>{suffix}</span>}
    </div>
  </div>
);

export default InputField;