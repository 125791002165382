// src/styles/utils.js
import { theme } from './theme';

export const createTransition = (properties = ['all'], options = {}) => {
  const {
    duration = theme.transitions.base,
    timing = 'ease',
    delay = '0s'
  } = options;
  
  return properties
    .map(property => `${property} ${duration} ${timing} ${delay}`)
    .join(', ');
};

export const mediaQuery = (breakpoint) => {
  return `@media (max-width: ${theme.breakpoints[breakpoint]})`;
};

export const createResponsiveStyles = (styles) => {
  const { mobile, tablet, laptop, desktop, ...baseStyles } = styles;
  
  return {
    ...baseStyles,
    [mediaQuery('desktop')]: desktop,
    [mediaQuery('laptop')]: laptop,
    [mediaQuery('tablet')]: tablet,
    [mediaQuery('mobile')]: mobile
  };
};