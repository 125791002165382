// src/styles/theme.js

export const theme = {
    colors: {
      primary: '#1E3D59',    // Deep Blue
      secondary: '#17A2B8',  // Vibrant Teal
      accent: '#FF6B6B',     // Warm Orange
      text: {
        primary: '#334155',
        secondary: '#64748b',
        light: '#94a3b8'
      },
      background: {
        main: '#F0F7FA',     // Light Blue background
        white: '#FFFFFF',
        light: '#f8fafc',
        tint: '#f0f9ff'
      },
      state: {
        success: '#28A745',
        warning: '#FFC107',
        error: '#dc2626'
      },
      border: '#e2e8f0'
    },
  
    typography: {
      fonts: {
        primary: "'Inter', sans-serif",
        secondary: "'Source Sans Pro', sans-serif",
        mono: "'IBM Plex Mono', monospace"
      },
      sizes: {
        xs: '12px',
        sm: '14px',
        base: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '24px',
        '3xl': '28px',
        '4xl': '32px'
      },
      weights: {
        normal: '400',
        medium: '500',
        semibold: '600',
        bold: '700'
      }
    },
  
    spacing: {
      xs: '8px',
      sm: '12px',
      md: '16px',
      lg: '24px',
      xl: '32px',
      '2xl': '40px',
      '3xl': '48px'
    },
  
    shadows: {
      sm: '0 1px 3px rgba(0,0,0,0.1)',
      md: '0 4px 6px rgba(0,0,0,0.1)',
      lg: '0 10px 15px rgba(0,0,0,0.1)'
    },
  
    radius: {
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '12px'
    },
  
    transitions: {
      fast: '0.2s ease',
      base: '0.3s ease',
      slow: '0.5s ease'
    },
  
    breakpoints: {
      mobile: '480px',
      tablet: '768px',
      laptop: '1024px',
      desktop: '1200px'
    }
  };