// src/components/Calculator/MortgageBreakdown.jsx
import React from 'react';
import { Info } from 'lucide-react';
import { styles } from './styles';
import { theme } from '../../styles/theme';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
};

const MortgageStats = ({ mortgageStats }) => (
  <div style={styles.costsBreakdown}>
    <h3 style={styles.costsBreakdownTitle}>Mortgage Payment Breakdown</h3>
    <div style={styles.costsGrid}>
      <div style={styles.costItem}>
        <div style={styles.costHeader}>
          <h4 style={styles.costTitle}>Principal Amount</h4>
          <span style={styles.costBadge}>Total Borrowed</span>
        </div>
        <p style={styles.costRange}>
          {formatCurrency(mortgageStats.principalAmount)}
        </p>
      </div>

      <div style={styles.costItem}>
        <div style={styles.costHeader}>
          <h4 style={styles.costTitle}>Interest Rate</h4>
          <span style={styles.costBadge}>Fixed Rate</span>
        </div>
        <p style={styles.costRange}>
          {mortgageStats.interestRate.toFixed(2)}%
        </p>
      </div>

      <div style={styles.costItem}>
        <div style={styles.costHeader}>
          <h4 style={styles.costTitle}>Loan Term</h4>
          <span style={styles.costBadge}>Duration</span>
        </div>
        <p style={styles.costRange}>
          {mortgageStats.mortgageYears} years
        </p>
      </div>

      <div style={styles.costItem}>
        <div style={styles.costHeader}>
          <h4 style={styles.costTitle}>Total Interest</h4>
          <span style={styles.costBadge}>Over Full Term</span>
        </div>
        <p style={styles.costRange}>
          {formatCurrency(mortgageStats.totalInterest)}
        </p>
      </div>
    </div>
  </div>

);

const MortgageBreakdown = ({ amortizationSchedule, mortgageStats }) => {
  return (
    <div style={styles.mortgageBreakdown}>
      {/* Summary Stats */}
      <MortgageStats mortgageStats={mortgageStats} />

      {/* Table Container */}
      <div style={styles.tableContainer}>
        <div style={styles.tableHeader}>
          <h3 style={styles.tableTitle}>First Year Monthly Payments</h3>
        </div>

        <div style={styles.tableWrapper}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.columnHeader}>Month</th>
                <th style={styles.columnHeader}>Principal</th>
                <th style={styles.columnHeader}>Interest</th>
                <th style={styles.columnHeader}>Total Payment</th>
                <th style={styles.columnHeader}>Remaining Balance</th>
              </tr>
            </thead>
            <tbody>
              {amortizationSchedule.map((payment) => (
                <tr key={payment.month} style={styles.tableRow}>
                  <td style={styles.monthCell}>{payment.month}</td>
                  <td style={styles.numberCell}>{formatCurrency(payment.principalPayment)}</td>
                  <td style={styles.numberCell}>{formatCurrency(payment.interestPayment)}</td>
                  <td style={styles.numberCell}>{formatCurrency(payment.totalPayment)}</td>
                  <td style={styles.numberCell}>{formatCurrency(payment.remainingBalance)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={styles.footer}>
          <Info size={16} color={theme.colors.text.secondary} />
          <p style={styles.footerText}>
            Assumes fixed interest rate, monthly compounding, and no extra repayments
          </p>
        </div>
      </div>
    </div>
  );
};

export default MortgageBreakdown;