// src/utils/calculations.js

export const calculateMortgage = (formData, countryConfig) => {
  // Start with base multiplier from country config
  let baseMultiplier = countryConfig.baseMultiplier;
  const annualIncome = Number(formData.annualIncome);
  
  // Adjust base multiplier for high income based on country config
  if (annualIncome > countryConfig.highIncomeThreshold) {
    baseMultiplier = countryConfig.highIncomeMultiplier;
  }
  
  // Step 1: Employment Type Adjustment from country config
  const selectedEmploymentType = countryConfig.employmentTypes.find(
    type => type.value === formData.employmentType
  );
  const employmentMultiplier = 1 - (selectedEmploymentType?.reduction || 0);

  // Step 2: Residency Duration Adjustment from country config
  const monthsInCountry = Number(formData.monthsInNetherlands);
  let residencyMultiplier;
  
  if (monthsInCountry < 3) {
    residencyMultiplier = countryConfig.residencyRules.lessThan3Months;
  } else if (monthsInCountry < 6) {
    residencyMultiplier = countryConfig.residencyRules.lessThan6Months;
  } else if (monthsInCountry < 12) {
    residencyMultiplier = countryConfig.residencyRules.lessThan12Months;
  } else {
    residencyMultiplier = countryConfig.residencyRules.established;
  }

  // Calculate final multiplier by combining all factors
  const multiplier = baseMultiplier * employmentMultiplier * residencyMultiplier;
  
  // Store the multiplier breakdown for transparency
  const multiplierBreakdown = {
    baseMultiplier,
    employmentMultiplier,
    residencyMultiplier,
    finalMultiplier: multiplier,
    reductions: {
      employment: ((1 - employmentMultiplier) * 100).toFixed(0) + '%',
      residency: ((1 - residencyMultiplier) * 100).toFixed(0) + '%',
      total: ((1 - (employmentMultiplier * residencyMultiplier)) * 100).toFixed(0) + '%'
    }
  };

  // Calculate maximum mortgage based on final multiplier
  const maxMortgage = annualIncome * multiplier;

  // Monthly payment calculations
  const interestRate = Number(formData.customInterestRate);
  const baseRate = interestRate / 100;
  const mortgageYears = Number(formData.mortgageDuration);
  const numberOfPayments = mortgageYears * 12;
  const monthlyInterestRate = baseRate / 12;

  // Monthly Payment Formula: P = L[c(1 + c)^n]/[(1 + c)^n - 1]
  const monthlyPayment = 
    (maxMortgage * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

  // Calculate amortization schedule for the first year
  const amortizationSchedule = [];
  let remainingBalance = maxMortgage;

  for (let month = 1; month <= 12; month++) {
    const interestPayment = remainingBalance * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;
    remainingBalance -= principalPayment;

    amortizationSchedule.push({
      month,
      principalPayment,
      interestPayment,
      totalPayment: monthlyPayment,
      remainingBalance
    });
  }

  // Calculate total payments
  const totalPayments = monthlyPayment * numberOfPayments;
  const totalInterest = totalPayments - maxMortgage;

  // Calculate additional buying costs from country config
  const transferTax = maxMortgage * countryConfig.transferTaxRate;
  const { notaryFees, advisorFees, bankFees } = countryConfig.additionalCosts;
  const additionalCosts = transferTax + notaryFees + advisorFees + bankFees;

  // Return comprehensive calculation results
  return {
    maxMortgage,
    monthlyPayment,
    additionalCosts,
    transferTax,
    notaryFees,
    advisorFees,
    bankFees,
    amortizationSchedule,
    mortgageStats: {
      totalPayments,
      totalInterest,
      interestRate: baseRate * 100,
      mortgageYears,
      principalAmount: maxMortgage,
      multipliers: multiplierBreakdown,
      maxPotentialMortgage: annualIncome * baseMultiplier,
      totalReduction: maxMortgage - (annualIncome * baseMultiplier),
      // Add country-specific details
      countryCode: countryConfig.code,
      countryRules: {
        transferTaxRate: countryConfig.transferTaxRate,
        baseMultiplier: countryConfig.baseMultiplier,
        highIncomeThreshold: countryConfig.highIncomeThreshold
      }
    },
    feasible: Number(formData.savings) >= additionalCosts
  };
};