// src/components/Guide/styles.js
import { theme } from '../../styles/theme';
import { createResponsiveStyles } from '../../styles/utils';

export const styles = {
  // Main Container
  container: {
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.md,
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.xl
  },

  mainTitle: {
    fontSize: theme.typography.sizes['3xl'],
    fontFamily: theme.typography.fonts.primary,
    marginBottom: theme.spacing.xl,
    color: theme.colors.text.primary,
    textAlign: 'center',
    fontWeight: theme.typography.weights.semibold
  },

  // Quick Start Section
  quickStart: {
    backgroundColor: theme.colors.background.tint,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
    border: `1px solid ${theme.colors.secondary}20`
  },

  quickStartTitle: {
    color: theme.colors.secondary,
    fontSize: theme.typography.sizes.lg,
    marginBottom: theme.spacing.xs,
    fontWeight: theme.typography.weights.semibold,
    fontFamily: theme.typography.fonts.primary
  },

  quickStartDescription: {
    color: theme.colors.text.secondary,
    fontSize: theme.typography.sizes.sm,
    lineHeight: 1.6
  },

  // Collapsible Sections
  collapsibleSection: {
    marginBottom: theme.spacing.md,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    backgroundColor: theme.colors.background.white
  },

  collapsibleButton: {
    width: '100%',
    padding: theme.spacing.lg,
    backgroundColor: theme.colors.background.light,
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    fontFamily: theme.typography.fonts.primary,
    '&:hover': {
      backgroundColor: theme.colors.background.tint
    }
  },

  collapsibleContent: {
    padding: theme.spacing.xl
  },

  // Section Headers
  sectionTitle: {
    fontSize: theme.typography.sizes.lg,
    color: theme.colors.primary,
    margin: 0,
    fontWeight: theme.typography.weights.semibold,
    fontFamily: theme.typography.fonts.primary
  },

  subsection: {
    marginBottom: theme.spacing.xl,
    padding: theme.spacing.lg,
    backgroundColor: theme.colors.background.light,
    borderRadius: theme.radius.lg
  },

  subsectionTitle: {
    fontSize: theme.typography.sizes.base,
    color: theme.colors.text.primary,
    marginBottom: theme.spacing.md,
    fontWeight: theme.typography.weights.semibold,
    fontFamily: theme.typography.fonts.primary
  },

  // Lists and Items
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },

  listItem: {
    padding: `${theme.spacing.sm} 0`,
    borderBottom: `1px solid ${theme.colors.border}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },

  nestedList: {
    listStyle: 'disc',
    paddingLeft: theme.spacing.xl,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    color: theme.colors.text.secondary
  },

  // Timeline Sections
  timelineSection: {
    marginBottom: theme.spacing.xl,
    padding: theme.spacing.lg,
    backgroundColor: theme.colors.background.white,
    borderLeft: `3px solid ${theme.colors.primary}`,
    borderRadius: `0 ${theme.radius.lg} ${theme.radius.lg} 0`,
    boxShadow: theme.shadows.sm
  },

  timelineTitle: {
    fontSize: theme.typography.sizes.lg,
    color: theme.colors.primary,
    marginBottom: theme.spacing.sm,
    fontWeight: theme.typography.weights.semibold
  },

  timelineDescription: {
    color: theme.colors.text.secondary,
    marginBottom: theme.spacing.sm,
    fontSize: theme.typography.sizes.sm,
    lineHeight: 1.6
  },

  timeline: {
    padding: `${theme.spacing.xl} 0`
  },

  timelineItem: createResponsiveStyles({
    position: 'relative',
    paddingLeft: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    marginBottom: theme.spacing.sm,
    borderLeft: `2px solid ${theme.colors.border}`,
    '&:last-child': {
      borderLeft: 'none',
      marginBottom: 0,
      paddingBottom: 0
    },
    
    mobile: {
      paddingLeft: theme.spacing.lg
    }
  }),

  timelineDuration: {
    display: 'inline-block',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    backgroundColor: theme.colors.background.light,
    borderRadius: theme.radius.md,
    fontSize: theme.typography.sizes.sm,
    marginBottom: theme.spacing.sm,
    color: theme.colors.text.secondary,
    fontWeight: theme.typography.weights.medium
  },

  // Notes and Additional Info
  note: {
    marginTop: theme.spacing.xs,
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    fontStyle: 'italic'
  },

  documentList: {
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    color: theme.colors.text.secondary,
    fontWeight: theme.typography.weights.medium
  },

  asteriskNote: {
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    marginTop: theme.spacing.sm,
    fontStyle: 'italic'
  },

  // Info Boxes
  infoBox: {
    backgroundColor: theme.colors.background.tint,
    border: `1px solid ${theme.colors.secondary}20`,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.lg,
    marginTop: theme.spacing.xl
  },

  infoTitle: {
    color: theme.colors.secondary,
    fontSize: theme.typography.sizes.base,
    marginBottom: theme.spacing.sm,
    fontWeight: theme.typography.weights.semibold
  },

  infoList: {
    margin: 0,
    padding: `0 0 0 ${theme.spacing.lg}`,
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    '& li': {
      marginBottom: theme.spacing.xs
    }
  },

  // Residency Selector
  residencySelector: createResponsiveStyles({
    backgroundColor: theme.colors.background.light,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
    border: `1px solid ${theme.colors.border}`,
    
    mobile: {
      padding: theme.spacing.lg
    }
  }),

  selectorHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.md
  },

  selectorTitle: {
    fontSize: theme.typography.sizes.base,
    color: theme.colors.text.primary,
    margin: 0,
    fontWeight: theme.typography.weights.semibold,
    fontFamily: theme.typography.fonts.primary
  },

  buttonGroup: {
    display: 'flex',
    gap: theme.spacing.sm
  },

  selectorButton: {
    flex: 1,
    padding: theme.spacing.md,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.background.white,
    color: theme.colors.text.secondary,
    fontSize: theme.typography.sizes.sm,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    fontFamily: theme.typography.fonts.primary,
    '&:hover': {
      backgroundColor: theme.colors.background.tint
    }
  },

  selectorButtonActive: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.background.white,
    borderColor: theme.colors.primary,
    '&:hover': {
      backgroundColor: theme.colors.secondary
    }
  },

  infoIcon: {
    cursor: 'pointer',
    color: theme.colors.primary,
    '&:hover': {
      color: theme.colors.secondary
    }
  },

  infoPopup: createResponsiveStyles({
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.lg,
    marginBottom: theme.spacing.md,
    border: `1px solid ${theme.colors.border}`,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing.md,
    
    mobile: {
      gridTemplateColumns: '1fr'
    }
  }),

  infoSection: {
    padding: theme.spacing.sm
  }
};