import React from 'react';
import { styles } from './styles';
import { AlertTriangle } from 'lucide-react';

const LegalDisclaimer = () => (
    <div style={styles.disclaimer}>
      <h3 style={styles.disclaimerTitle}>
        <AlertTriangle size={20} />
        Important Legal Disclaimer
      </h3>
      <div style={styles.disclaimerContent}>
        <p>
          This mortgage calculator is provided for illustrative and educational purposes only. 
          The calculations and information presented here are:
        </p>
        <ul style={styles.disclaimerList}>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Not Financial Advice:</span> The information provided 
            should not be construed as financial, legal, or professional advice. Always consult with a 
            qualified mortgage advisor, financial professional, or legal expert before making any financial decisions.
          </li>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Estimates Only:</span> All calculations, including mortgage 
            amounts, monthly payments, and additional costs, are approximations and may vary significantly from 
            actual amounts offered by financial institutions.
          </li>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Subject to Change:</span> Interest rates, lending criteria, 
            and additional costs can change frequently. The actual terms and conditions will be determined by 
            your chosen mortgage provider.
          </li>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Individual Circumstances:</span> Your actual eligibility, 
            mortgage amount, and terms will depend on various factors including but not limited to credit history, 
            employment status, and specific lender requirements.
          </li>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Not a Lending Offer:</span> This calculator does not 
            constitute an offer for a mortgage or any form of loan. All mortgage applications are subject to 
            lender approval and terms.
          </li>
          <li style={styles.disclaimerItem}>
            <span style={styles.disclaimerEmphasis}>Seek Professional Advice:</span> We strongly recommend 
            consulting with a licensed mortgage advisor who can provide personalized advice based on your 
            specific situation and current market conditions.
          </li>
        </ul>
        <p>
          By using this calculator, you acknowledge that you understand these limitations and will not rely 
          solely on this information for making financial decisions. The creators and operators of this 
          calculator accept no liability for decisions made based on these calculations.
        </p>
      </div>
    </div>
  );

export default LegalDisclaimer;