import React, { useState } from 'react';
import Calculator from './components/Calculator/Calculator';
import Guide from './components/Guide/Guide';
import CountrySelector from './components/CountrySelector';
import AnalyticsTracker from './components/AnalyticsTracker';
import ContactSection from './components/Contact/ContactSection';
import { container } from './components/shared/styles';
import LegalDisclaimer from './components/LegalDisclaimer/LegalDisclaimer';

// Default country configuration
const defaultCountry = {
  code: 'NL',
  name: 'Netherlands',
  flag: '🇳🇱',
  config: {
    transferTaxRate: 0.02,
    mortgageYears: [10, 15, 20, 25, 30],
    baseMultiplier: 4.5,
    highIncomeThreshold: 80000,
    highIncomeMultiplier: 5.0,
    employmentTypes: [
      { value: 'permanent', label: 'Permanent Contract', reduction: 0 },
      { value: 'temporary', label: 'Temporary Contract', reduction: 0.1 },
      { value: 'self-employed', label: 'Self Employed', reduction: 0.2 }
    ],
    residencyRules: {
      lessThan3Months: 0.7,
      lessThan6Months: 0.8,
      lessThan12Months: 0.9,
      established: 1.0
    },
    additionalCosts: {
      notaryFees: 2000,
      advisorFees: 2500,
      bankFees: 750
    }
  }
};

const Logo = () => (
  <svg width="48" height="48" viewBox="0 0 32 32" style={{ marginRight: '16px' }}>
    <circle cx="16" cy="16" r="16" fill="#1E3D59"/>
    <path 
      d="M16 6L7 13.5V25H25V13.5L16 6Z" 
      fill="white" 
      stroke="#17A2B8" 
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect x="14" y="18" width="4" height="7" fill="#17A2B8"/>
    <path 
      d="M12 15H20L16 11L12 15Z" 
      fill="#17A2B8" 
      stroke="#17A2B8" 
      strokeWidth="1"
      strokeLinejoin="round"
    />
  </svg>
);

const App = () => {
  const [activeSection, setActiveSection] = useState('calculator');
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);


  const headerStyles = {
    textAlign: 'left',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px 16px'
  };

  const titleWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
  };

  const titleStyles = {
    fontSize: '42px',
    fontWeight: '600',
    color: '#1E3D59',
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  };

  const subtitleStyles = {
    color: '#64748b',
    fontSize: '18px',
    margin: '0',
  };

  const renderContent = () => {
    if (!selectedCountry) {
      return (
        <div style={{
          textAlign: 'center',
          padding: '40px 20px',
          color: '#6B7280',
          fontSize: '16px'
        }}>
          Please select a country to view mortgage options
        </div>
      );
    }

    return (
      <>
        <nav style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '12px',
          marginBottom: '24px'
        }}>
          <button
            onClick={() => setActiveSection('calculator')}
            style={{
              padding: '10px 20px',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: '500',
              cursor: 'pointer',
              border: 'none',
              minWidth: '120px',
              backgroundColor: activeSection === 'calculator' ? '#1E3D59' : '#e5e7eb',
              color: activeSection === 'calculator' ? 'white' : '#374151',
            }}
          >
            Calculator
          </button>
          <button
            onClick={() => setActiveSection('guide')}
            style={{
              padding: '10px 20px',
              borderRadius: '6px',
              fontSize: '16px',
              fontWeight: '500',
              cursor: 'pointer',
              border: 'none',
              minWidth: '120px',
              backgroundColor: activeSection === 'guide' ? '#1E3D59' : '#e5e7eb',
              color: activeSection === 'guide' ? 'white' : '#374151',
            }}
          >
            Housing Guide
          </button>
        </nav>

        {activeSection === 'calculator' ? (
          <Calculator 
            countryConfig={selectedCountry.config}
            countryCode={selectedCountry.code}
          />
        ) : (
          <Guide 
            countryConfig={selectedCountry.config}
            countryCode={selectedCountry.code}
          />
        )}
      </>
    );
  };

  return (
    <div style={container}>
      <AnalyticsTracker />
      <header style={headerStyles}>
        <div style={titleWrapperStyles}>
          <Logo />
          <h1 style={titleStyles}>Life Relocate</h1>
        </div>
        <p style={subtitleStyles}>
          Relocate your life to many european cities!
        </p>
      </header>
      
      <CountrySelector 
        selectedCountry={selectedCountry} 
        setSelectedCountry={setSelectedCountry} 
      />

      {renderContent()}
      <LegalDisclaimer />
      <ContactSection />
    </div>
  );
};

export default App;