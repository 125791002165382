  import { theme } from '../../styles/theme';

  export const styles = {
disclaimer: {
    marginTop: theme.spacing['2xl'],
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.border}`,
    boxShadow: theme.shadows.sm
  },

  disclaimerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.xs,
    fontSize: theme.typography.sizes.base,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.state.error,
    marginBottom: theme.spacing.md
  },

  disclaimerContent: {
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    lineHeight: 1.6
  },

  disclaimerList: {
    marginTop: theme.spacing.sm,
    paddingLeft: theme.spacing.xl,
    listStyle: 'disc'
  },

  disclaimerItem: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.text.secondary
  },

  disclaimerEmphasis: {
    fontWeight: theme.typography.weights.medium,
    color: theme.colors.text.primary
  }
}