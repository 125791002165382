// src/components/Contact/styles.js
import { theme } from '../../styles/theme';

export const styles = {
  container: {
    marginTop: theme.spacing['2xl'],
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.sm,
    border: `1px solid ${theme.colors.border}`
  },

  section: {
    padding: theme.spacing.xl,
  },

  divider: {
    height: '1px',
    backgroundColor: theme.colors.border,
    margin: '0',
    width: '100%'
  },

  contentWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing.lg
  },

  iconWrapper: {
    padding: theme.spacing.sm,
    backgroundColor: `${theme.colors.primary}10`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  icon: {
    color: theme.colors.primary
  },

  content: {
    flex: 1
  },

  title: {
    fontSize: theme.typography.sizes.lg,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    marginBottom: theme.spacing.sm,
    fontFamily: theme.typography.fonts.primary
  },

  alertBox: {
    padding: theme.spacing.lg,
    backgroundColor: `${theme.colors.primary}05`,
    borderLeft: `4px solid ${theme.colors.primary}`,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg
  },

  alertTitle: {
    color: theme.colors.primary,
    fontWeight: theme.typography.weights.medium,
    marginBottom: theme.spacing.xs,
    fontFamily: theme.typography.fonts.primary
  },

  alertDescription: {
    color: theme.colors.text.secondary,
    fontSize: theme.typography.sizes.base,
    lineHeight: 1.5
  },

  bulletList: {
    marginTop: theme.spacing.sm,
    paddingLeft: theme.spacing.xl,
    color: theme.colors.text.secondary,
    '& li': {
      marginBottom: theme.spacing.xs
    }
  },

  emailLink: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing.sm,
    color: theme.colors.primary,
    fontWeight: theme.typography.weights.medium,
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    fontFamily: theme.typography.fonts.primary,
    '&:hover': {
      color: theme.colors.secondary
    }
  },

  responseTime: {
    marginTop: theme.spacing.md,
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    fontFamily: theme.typography.fonts.secondary
  }
};