import { useEffect } from 'react';

const AnalyticsTracker = () => {
  useEffect(() => {
    // Load Google Analytics script
    const loadGA = () => {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-8NREDN4KXK`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-8NREDN4KXK');
      };
    };

    loadGA();
  }, []);

  return null; // This component doesn't render anything
};

export default AnalyticsTracker;