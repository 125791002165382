// src/components/Calculator/Calculator.jsx
import React, { useState, useEffect } from 'react';
import { Euro, User, Clock, Percent, Info } from 'lucide-react';
import { calculateMortgage } from '../../utils/calculations';
import { styles } from './styles';
import MortgageBreakdown from './MortgageBreakdown';
import InputField from './InputField';
import SelectField from './SelectField';
import EmploymentTypeTooltip from './EmploymentTypeTooltip';

const formatCurrency = (amount) => {
  return `€${amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
};

const Calculator = ({ countryConfig, countryCode }) => {
  const [showResidencyInfo, setShowResidencyInfo] = useState(false);
  const [formData, setFormData] = useState({
    annualIncome: '',
    savings: '',
    employmentType: 'permanent',
    monthsInCountry: '',
    customInterestRate: '4.5',
    fixedRatePeriod: '10',
    mortgageDuration: countryConfig.mortgageYears[countryConfig.mortgageYears.length - 1].toString()
  });

  const [results, setResults] = useState(null);

  // Reset form when country changes
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      mortgageDuration: countryConfig.mortgageYears[countryConfig.mortgageYears.length - 1].toString()
    }));
  }, [countryCode, countryConfig]);

  const handleCalculate = () => {
    const calculationResults = calculateMortgage(formData, countryConfig);
    setResults(calculationResults);
  };

  return (
    <div style={styles.container}>
      <div style={styles.calculatorForm}>

        {/* Annual Gross Income */}
        <InputField
          label="Annual Gross Income (€)"
          icon={Euro}
          type="number"
          value={formData.annualIncome}
          onChange={(e) => setFormData({ ...formData, annualIncome: e.target.value })}
          placeholder="60000"
        />

        {/* Available Savings */}
        <InputField
          label="Available Savings (€)"
          icon={Euro}
          type="number"
          value={formData.savings}
          onChange={(e) => setFormData({ ...formData, savings: e.target.value })}
          placeholder="25000"
        />

        {/* Interest Rate */}
        <InputField
          label="Interest Rate (%)"
          icon={Percent}
          type="number"
          step="0.1"
          value={formData.customInterestRate}
          onChange={(e) => setFormData({ ...formData, customInterestRate: e.target.value })}
          placeholder="4.5"
        />

        {/* Fixed Rate Period */}
        <SelectField
          label="Fixed Rate Period"
          icon={Clock}
          value={formData.fixedRatePeriod}
          onChange={(e) => setFormData({ ...formData, fixedRatePeriod: e.target.value })}
          options={[
            { value: "1", label: "1 year" },
            { value: "5", label: "5 years" },
            { value: "10", label: "10 years" },
            { value: "20", label: "20 years" },
            { value: "30", label: "30 years" }
          ]}
        />

        {/* Mortgage Duration */}
        <SelectField
          label="Mortgage Duration"
          icon={Clock}
          value={formData.mortgageDuration}
          onChange={(e) => setFormData({ ...formData, mortgageDuration: e.target.value })}
          options={[
            { value: "10", label: "10 years" },
            { value: "15", label: "15 years" },
            { value: "20", label: "20 years" },
            { value: "25", label: "25 years" },
            { value: "30", label: "30 years" }
          ]}
        />

        {/* Employment Type with Enhanced Tooltip */}
        <EmploymentTypeTooltip
          value={formData.employmentType}
          onChange={(e) => setFormData({ ...formData, employmentType: e.target.value })}
        />

        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
            <label style={styles.label}>Months in Netherlands</label>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Info 
                size={20} 
                style={{ cursor: 'pointer', color: '#17A2B8' }}
                onMouseEnter={() => setShowResidencyInfo(true)}
                onMouseLeave={() => setShowResidencyInfo(false)}
              />
              <div style={{
                display: showResidencyInfo ? 'block' : 'none',
                position: 'absolute',
                right: '0',
                top: '100%',
                width: '300px',
                padding: '15px',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                borderRadius: '8px',
                zIndex: 10,
                fontSize: '14px'
              }}>
                <h4 style={{ marginTop: 0, marginBottom: '10px', color: '#17A2B8' }}>
                  How Residency Duration Normally Affects Your Mortgage
                </h4>
                <div style={{ marginBottom: '10px' }}>
                  <strong>Less than 3 months:</strong>
                  <p>30% reduction in lending capacity. Most banks require longer residency.</p>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <strong>3-6 months:</strong>
                  <p>20% reduction. Banks consider this probation period with higher risk.</p>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <strong>6-12 months:</strong>
                  <p>10% reduction. More banks willing to consider with proven stability.</p>
                </div>
                <div>
                  <strong>Over 12 months:</strong>
                  <p>No reduction. Standard lending criteria apply.</p>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.inputWrapper}>
            <div style={styles.icon}>
              <User size={20} />
            </div>
            <input
              type="number"
              style={styles.input}
              value={formData.monthsInNetherlands}
              onChange={(e) => setFormData({ ...formData, monthsInNetherlands: e.target.value })}
              placeholder="0"
            />
          </div>
        </div>

        <button 
          type="button"
          onClick={handleCalculate}
          style={styles.button}
          onMouseOver={(e) => e.target.style.backgroundColor = '#17A2B8'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#1E3D59'}
        >
          Calculate Your Options
        </button>
      </div>

      {results && (
        <div style={styles.results}>
          <div style={styles.resultsGrid}>
            <div style={{...styles.resultBox, backgroundColor: '#e8f4f8', borderLeft: '4px solid #3b82f6'}}>
              <h3>Maximum Mortgage</h3>
              <p style={styles.resultValue}>{formatCurrency(results.maxMortgage)}</p>
            </div>

            <div style={{...styles.resultBox, backgroundColor: '#e8f8f5', borderLeft: '4px solid #10b981'}}>
              <h3>Monthly Payment</h3>
              <p style={styles.resultValue}>{formatCurrency(results.monthlyPayment)}</p>
            </div>

            <div style={{...styles.resultBox, backgroundColor: '#f8e8f8', borderLeft: '4px solid #8b5cf6'}}>
              <h3>Additional Costs</h3>
              <p style={styles.resultValue}>
                {formatCurrency(results.additionalCosts * 0.9)} - {formatCurrency(results.additionalCosts * 1.1)}
              </p>
            </div>
          </div>

          <div style={styles.costsBreakdown}>
            <h3 style={styles.costsBreakdownTitle}>Additional Costs Breakdown</h3>
            <div style={styles.costsGrid}>
              <div style={styles.costItem}>
                <div style={styles.costHeader}>
                  <h4>Transfer Tax</h4>
                  <span style={styles.costBadge}>2% of property value</span>
                </div>
                <p style={styles.costRange}>
                  {formatCurrency(results.transferTax * 0.95)} - {formatCurrency(results.transferTax * 1.05)}
                </p>
                <p style={styles.costNote}>Required tax when buying property</p>
              </div>

              <div style={styles.costItem}>
                <div style={styles.costHeader}>
                  <h4>Notary Fees</h4>
                  <span style={styles.costBadge}>Fixed Range</span>
                </div>
                <p style={styles.costRange}>€1,500 - €2,500</p>
                <p style={styles.costNote}>Legal documentation and registration</p>
              </div>

              <div style={styles.costItem}>
                <div style={styles.costHeader}>
                  <h4>Mortgage Advisor</h4>
                  <span style={styles.costBadge}>Fixed Range</span>
                </div>
                <p style={styles.costRange}>€2,000 - €3,000</p>
                <p style={styles.costNote}>Professional financial advice</p>
              </div>

              <div style={styles.costItem}>
                <div style={styles.costHeader}>
                  <h4>Bank & Valuation Fees</h4>
                  <span style={styles.costBadge}>Fixed Range</span>
                </div>
                <p style={styles.costRange}>€500 - €1,000</p>
                <p style={styles.costNote}>Required for mortgage approval</p>
              </div>
            </div>
          </div>

          <MortgageBreakdown 
            amortizationSchedule={results.amortizationSchedule}
            mortgageStats={results.mortgageStats}
          />
        </div>
      )}
    </div>
  );
};

export default Calculator;