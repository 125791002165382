// src/components/shared/styles.js
import { theme } from '../../styles/theme';
import { createResponsiveStyles } from '../../styles/utils';


export const container = createResponsiveStyles({
  maxWidth: theme.breakpoints.desktop,
  margin: '0 auto',
  padding: theme.spacing.lg,
  fontFamily: theme.typography.fonts.secondary,
  
  tablet: {
    padding: theme.spacing.md
  },
  mobile: {
    padding: theme.spacing.sm
  }
});

export const header = {
  fontFamily: theme.typography.fonts.primary,
  fontSize: theme.typography.sizes['3xl'],
  fontWeight: theme.typography.weights.semibold,
  color: theme.colors.primary,
  marginBottom: theme.spacing.md
};

export const title = {
  fontSize: theme.typography.sizes['4xl'],
  color: theme.colors.text.primary,
  marginBottom: theme.spacing.sm
};

export const subtitle = {
  color: theme.colors.text.secondary,
  fontSize: theme.typography.sizes.lg
};

export const button = {
  width: '100%',
  padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  backgroundColor: theme.colors.primary,
  color: theme.colors.background.white,
  border: 'none',
  borderRadius: theme.radius.md,
  fontSize: theme.typography.sizes.base,
  cursor: 'pointer',
  fontWeight: theme.typography.weights.medium,
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.colors.secondary
  }
};

export const card = {
  backgroundColor: theme.colors.background.white,
  borderRadius: theme.radius.lg,
  boxShadow: theme.shadows.md,
  padding: theme.spacing.lg,
  border: `1px solid ${theme.colors.border}`
};