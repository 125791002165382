import React from 'react';
import { styles } from './styles';

const SelectField = ({ label, icon: Icon, value, onChange, options, ...props }) => (
    <div style={styles.formGroup}>
      <label style={styles.label}>{label}</label>
      <div style={styles.selectWrapper}>
        <div style={styles.selectIcon}>
          <Icon size={20} />
        </div>
        <select
          style={styles.select}
          value={value}
          onChange={onChange}
          {...props}
        >
          {options.map(option => (
            <option 
              key={option.value} 
              value={option.value}
              style={value === option.value ? styles.selectSelected : {}}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

export default SelectField