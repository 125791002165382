// src/components/Calculator/styles.js
import { theme } from '../../styles/theme';
import { createResponsiveStyles } from '../../styles/utils';

export const styles = {
  // Main Calculator Container & Form Styles
  container: createResponsiveStyles({
    maxWidth: theme.breakpoints.desktop,
    margin: '0 auto',
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.background.main,
    
    tablet: {
      padding: theme.spacing.lg
    },
    mobile: {
      padding: theme.spacing.md
    }
  }),

  calculatorForm: {
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    padding: theme.spacing.xl,
    boxShadow: theme.shadows.md,
    marginBottom: theme.spacing.xl
  },

  formGroup: {
    marginBottom: theme.spacing.lg
  },

  label: {
    display: 'block',
    marginBottom: theme.spacing.xs,
    fontFamily: theme.typography.fonts.primary,
    fontWeight: theme.typography.weights.medium,
    color: theme.colors.text.primary,
    fontSize: theme.typography.sizes.sm
  },

  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  input: {
    width: '100%',
    height: '44px', // Fixed height for consistency
    paddingLeft: '40px', // Space for icon
    paddingRight: '12px',
    fontSize: theme.typography.sizes.base,
    fontFamily: theme.typography.fonts.secondary,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.background.white,
    transition: 'all 0.3s ease',
    '&:focus': {
      outline: 'none',
      borderColor: theme.colors.secondary,
      boxShadow: `0 0 0 3px ${theme.colors.secondary}20`
    }
  },

  icon: {
    position: 'absolute',
    left: '12px',
    pointerEvents: 'none', // Ensures icon doesn't interfere with input
    color: theme.colors.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px'
  },

  select: {
    width: '100%',
    height: '44px',
    padding: '0 12px 0 40px', // Left padding for icon
    fontSize: theme.typography.sizes.base,
    fontFamily: theme.typography.fonts.secondary,
    border: `1px solid ${theme.colors.border}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.background.white,
    appearance: 'none', // Removes default select styling
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    color: theme.colors.text.primary,
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 12px center',
    backgroundSize: '16px',
    '&:focus': {
      outline: 'none',
      borderColor: theme.colors.primary,
      boxShadow: `0 0 0 3px ${theme.colors.primary}20`
    },
    '&:hover': {
      borderColor: theme.colors.primary
    }
  },

  selectWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },

  selectIcon: {
    position: 'absolute',
    left: '12px',
    pointerEvents: 'none',
    color: theme.colors.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px'
  },

  // Add styles for selected state
  selectSelected: {
    backgroundColor: theme.colors.background.white,
    fontWeight: theme.typography.weights.medium
  },

  // Currency prefix style
  currencyPrefix: {
    position: 'absolute',
    left: '40px', // After the icon
    color: theme.colors.text.primary,
    pointerEvents: 'none'
  },

  // Percentage suffix style
  percentageSuffix: {
    position: 'absolute',
    right: '12px',
    color: theme.colors.text.primary,
    pointerEvents: 'none'
  },

  button: {
    width: '100%',
    padding: theme.spacing.md,
    backgroundColor: theme.colors.primary,
    color: theme.colors.background.white,
    border: 'none',
    borderRadius: theme.radius.md,
    fontSize: theme.typography.sizes.base,
    fontFamily: theme.typography.fonts.primary,
    fontWeight: theme.typography.weights.medium,
    cursor: 'pointer',
  },

  // Results Section
  results: {
    marginTop: theme.spacing.xl
  },

  resultsGrid: createResponsiveStyles({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing.lg,
    marginBottom: theme.spacing.xl,
    
    tablet: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    mobile: {
      gridTemplateColumns: '1fr',
    }
  }),

  resultBox: {
    padding: theme.spacing.lg,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.sm,
    transition: 'transform 0.3s ease',
    '& h3': {
      margin: '0 0 8px 0',
      fontSize: theme.typography.sizes.base,
      color: theme.colors.text.secondary
    },
    '&:hover': {
      transform: 'translateY(-2px)'
    }
  },

  maxMortgage: {
    backgroundColor: theme.colors.background.tint,
    borderLeft: `4px solid ${theme.colors.primary}`
  },

  monthlyPayment: {
    backgroundColor: theme.colors.background.light,
    borderLeft: `4px solid ${theme.colors.secondary}`
  },

  additionalCosts: {
    backgroundColor: theme.colors.background.tint,
    borderLeft: `4px solid ${theme.colors.accent}`
  },

  resultValue: {
    fontSize: theme.typography.sizes['2xl'],
    fontFamily: theme.typography.fonts.mono,
    fontWeight: theme.typography.weights.semibold,
    margin: 0
  },

  // Costs Breakdown Section
  costsBreakdown: {
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.xl,
    padding: theme.spacing.xl,
    marginTop: theme.spacing.xl,
    boxShadow: theme.shadows.sm
  },

  costsGrid: createResponsiveStyles({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: theme.spacing.md,
    marginBottom: theme.spacing.xl,
    
    mobile: {
      gridTemplateColumns: '1fr'
    }
  }),

  costItem: {
    backgroundColor: theme.colors.background.light,
    padding: '20px',
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.border}`
  },

  costHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px'
  },

  costTitle: {
    fontSize: theme.typography.sizes.base,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    margin: 0,
    fontFamily: theme.typography.fonts.primary
  },

  costBreakdownSection: {
    marginBottom: '40px'  // Consistent spacing
  },

  costsBreakdownTitle: {
    fontSize: theme.typography.sizes.xl,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    marginBottom: '20px',
    fontFamily: theme.typography.fonts.primary
  },

  costBadge: {
    backgroundColor: theme.colors.background.main,
    padding: '4px 8px',
    borderRadius: theme.radius.md,
    fontSize: theme.typography.sizes.xs,
    color: theme.colors.text.secondary,
    fontWeight: theme.typography.weights.medium,
    fontFamily: theme.typography.fonts.primary
  },

  costRange: {
    fontSize: theme.typography.sizes.lg,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.primary.main,
    marginBottom: '8px',
    fontFamily: theme.typography.fonts.mono,
    margin: 0
  },

  costDescription: {
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    margin: 0,
    lineHeight: 1.5
  },

  breakdownContainer: {
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    margin: '32px 0'
  },


  breakdownTitle: {
    fontSize: theme.typography.sizes['2xl'],
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    padding: '24px 32px',
    margin: 0,
    borderBottom: `1px solid ${theme.colors.border}`,
    fontFamily: theme.typography.fonts.primary
  },

  statsSection: {
    marginBottom: '40px',  // Increase space after stats
    borderBottom: `1px solid ${theme.colors.border}`
  },

  statsContainer: {
    padding: '24px 32px',
    backgroundColor: theme.colors.background.white
  },

  statLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 0',
    fontSize: theme.typography.sizes.base,
    color: theme.colors.text.primary,
    '& > span:first-child': {
      fontFamily: theme.typography.fonts.primary,
      fontWeight: theme.typography.weights.normal
    },
    '& > span:last-child': {
      fontFamily: theme.typography.fonts.mono,
      fontWeight: theme.typography.weights.medium
    }
  },

  paymentsTitle: {
    fontSize: theme.typography.sizes.lg,
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    padding: '24px 32px',
    margin: 0,
    borderBottom: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.background.light,
    fontFamily: theme.typography.fonts.primary
  },

  statsTitle: {
    fontSize: theme.typography.sizes['2xl'],
    fontWeight: theme.typography.weights.semibold,
    color: theme.colors.text.primary,
    margin: 0,
    padding: '24px',
    borderBottom: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.background.white,
    fontFamily: theme.typography.fonts.primary
  },

  statsContent: {
    padding: '16px 24px',
    backgroundColor: theme.colors.background.light,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },

  statsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.typography.sizes.base,
    padding: '8px 0'
  },

  statsLabel: {
    fontFamily: theme.typography.fonts.primary,
    color: theme.colors.text.primary,
    fontWeight: theme.typography.weights.normal
  },

  statsValue: {
    fontFamily: theme.typography.fonts.mono,
    color: theme.colors.text.primary,
    fontWeight: theme.typography.weights.medium
  },

  // Table Styles
  tableContainer: {
    backgroundColor: theme.colors.background.white,
    borderRadius: theme.radius.lg,
    overflow: 'hidden',
    margin: '32px 0'
  },

  tableSection: {
    marginTop: '32px'
  },

  tableTitle: {
    fontSize: theme.typography.sizes.lg,
    color: theme.colors.text.primary,
    fontWeight: theme.typography.weights.semibold,
    margin: 0,
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.colors.border}`,
    fontFamily: theme.typography.fonts.primary
  },
  
  tableHeader: {
    padding: '20px 24px',
    borderBottom: `1px solid ${theme.colors.border}`
  },
  tableWrapper: {
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch'
  },

  // Table Styles
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: theme.typography.sizes.sm
  },
  columnHeader: {
    padding: '16px 24px',
    backgroundColor: theme.colors.background.light,
    color: theme.colors.text.secondary,
    fontWeight: theme.typography.weights.medium,
    fontFamily: theme.typography.fonts.primary,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    borderBottom: `2px solid ${theme.colors.border}`,
    '&:first-child': {
      textAlign: 'left'
    }
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: theme.colors.background.light
    },
    '&:hover': {
      backgroundColor: `${theme.colors.primary.main}05`
    }
  },
  monthCell: {
    padding: '16px 24px',
    color: theme.colors.text.secondary,
    fontFamily: theme.typography.fonts.primary,
    borderBottom: `1px solid ${theme.colors.border}`,
    textAlign: 'left'
  },
  numberCell: {
    padding: '16px 24px',
    color: theme.colors.text.primary,
    fontFamily: theme.typography.fonts.mono,
    borderBottom: `1px solid ${theme.colors.border}`,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },

  // Footer
  footer: {
    padding: '16px 32px',
    backgroundColor: theme.colors.background.light,
    borderTop: `1px solid ${theme.colors.border}`,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },

  footerText: {
    fontSize: theme.typography.sizes.sm,
    color: theme.colors.text.secondary,
    fontFamily: theme.typography.fonts.primary,
    margin: 0
  },
};