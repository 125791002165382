// src/components/Guide/Guide.jsx
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'lucide-react';
import { styles } from './styles';

const ResidencySelector = ({ status, onStatusChange }) => {
  const [showInfo, setShowInfo] = useState(false);

  const infoContent = {
    eu: {
      title: "EU/EEA/Swiss Citizen",
      points: [
        "Right to live and work in Netherlands without visa",
        "Simplified registration process",
        "No residence permit required",
        "Can start working immediately",
        "Same mortgage conditions as Dutch citizens"
      ]
    },
    nonEu: {
      title: "Non-EU/EEA Citizen",
      points: [
        "Residence permit (verblijfsvergunning) required",
        "May need work permit (TWV)",
        "Highly skilled migrant status may apply",
        "Additional documentation needed",
        "Some banks may have special requirements"
      ]
    }
  };

  return (
    <div style={styles.residencySelector}>
      <div style={styles.selectorHeader}>
        <h3 style={styles.selectorTitle}>Select Your Residency Status</h3>
        <Info 
          size={20} 
          style={styles.infoIcon}
          onClick={() => setShowInfo(!showInfo)}
        />
      </div>

      {showInfo && (
        <div style={styles.infoPopup}>
          <div style={styles.infoSection}>
            <h4 style={styles.infoTitle}>{infoContent.eu.title}</h4>
            <ul style={styles.infoList}>
              {infoContent.eu.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
          <div style={styles.infoSection}>
            <h4 style={styles.infoTitle}>{infoContent.nonEu.title}</h4>
            <ul style={styles.infoList}>
              {infoContent.nonEu.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div style={styles.buttonGroup}>
        <button
          style={{
            ...styles.selectorButton,
            ...(status === 'eu' ? styles.selectorButtonActive : {})
          }}
          onClick={() => onStatusChange('eu')}
        >
          EU/EEA Citizen
        </button>
        <button
          style={{
            ...styles.selectorButton,
            ...(status === 'nonEu' ? styles.selectorButtonActive : {})
          }}
          onClick={() => onStatusChange('nonEu')}
        >
          Non-EU Citizen
        </button>
      </div>
    </div>
  );
};

const CollapsibleSection = ({ title, children, isOpen, onToggle }) => (
  <div style={styles.collapsibleSection}>
    <button 
      onClick={onToggle}
      style={styles.collapsibleButton}
    >
      <h3 style={styles.sectionTitle}>{title}</h3>
      {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
    </button>
    {isOpen && <div style={styles.collapsibleContent}>{children}</div>}
  </div>
);

const Guide = () => {
  const [residencyStatus, setResidencyStatus] = useState('eu');
  const [openSections, setOpenSections] = useState({
    initial: false,
    mortgage: false,
    process: false
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Helper function to get dynamic content based on residency status
  const getContent = (euContent, nonEuContent) => {
    return residencyStatus === 'eu' ? euContent : nonEuContent;
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.mainTitle}>Essential Guide to Dutch Mortgages</h2>
      
      <ResidencySelector 
        status={residencyStatus} 
        onStatusChange={setResidencyStatus} 
      />
      
      {/* Initial Registration Requirements Section */}
      <CollapsibleSection 
        title="1. Initial Registration Requirements"
        isOpen={openSections.initial}
        onToggle={() => toggleSection('initial')}
      >
        <div style={styles.subsection}>
          <h4 style={styles.subsectionTitle}>Essential Government Registration</h4>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>BSN Number (Burgerservicenummer)</strong>
              <p>Required for: employment, banking, healthcare</p>
              <p style={styles.documentList}>Documents needed:</p>
              <ul style={styles.nestedList}>
                <li>{getContent(
                  "Valid passport or EU ID card",
                  "Valid passport and residence permit"
                )}</li>
                <li>Birth certificate (apostilled)*</li>
                <li>Rental agreement or host declaration</li>
                {residencyStatus === 'nonEu' && (
                  <li>Entry visa (MVV) or residence permit</li>
                )}
              </ul>
            </li>
            
            <li style={styles.listItem}>
              <strong>Municipal Registration (BRP)</strong>
              <p>Required if staying over 4 months</p>
              <p style={styles.documentList}>Documents needed:</p>
              <ul style={styles.nestedList}>
                <li>{getContent(
                  "Valid passport or EU ID card",
                  "Valid passport and residence permit"
                )}</li>
                <li>Birth certificate (apostilled)*</li>
                <li>Marriage certificate (if applicable)*</li>
                <li>Rental contract</li>
                {residencyStatus === 'nonEu' && (
                  <li>Proof of legal stay (residence permit/visa)</li>
                )}
              </ul>
            </li>
          </ul>
          <p style={styles.asteriskNote}>* Non-Dutch documents require certified translations</p>
        </div>

        <div style={styles.subsection}>
          <h4 style={styles.subsectionTitle}>Financial Setup</h4>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Dutch Bank Account</strong>
              <p style={styles.documentList}>Required documents:</p>
              <ul style={styles.nestedList}>
                <li>BSN number</li>
                <li>{getContent(
                  "Valid passport or EU ID card",
                  "Valid passport and residence permit"
                )}</li>
                <li>Proof of address</li>
                {residencyStatus === 'nonEu' && (
                  <li>Valid residence permit with work authorization</li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </CollapsibleSection>

      {/* Mortgage Documentation Section */}
      <CollapsibleSection 
        title="2. Mortgage Application Process"
        isOpen={openSections.mortgage}
        onToggle={() => toggleSection('mortgage')}
      >
        <div style={styles.subsection}>
          <h4 style={styles.subsectionTitle}>Document Timeline for Mortgage Process</h4>

          <div style={styles.timelineSection}>
            <h5 style={styles.timelineTitle}>Stage 1: Initial Mortgage Approval</h5>
            <p style={styles.timelineDescription}>Required documents before house hunting:</p>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Identity & Registration:</strong>
                <ul style={styles.nestedList}>
                  <li>{getContent(
                    "Valid passport or EU ID card",
                    "Valid passport and residence permit"
                  )}</li>
                  <li>BSN number</li>
                  {residencyStatus === 'nonEu' && (
                    <>
                      <li>Residence permit (valid for at least 1 year)</li>
                      <li>Work permit (if applicable)</li>
                    </>
                  )}
                </ul>
              </li>
              <li style={styles.listItem}>
                <strong>Income Documents:</strong>
                <ul style={styles.nestedList}>
                  <li>Employment contract</li>
                  <li>Recent payslips (last 3 months)</li>
                  <li>Annual statement (jaaropgave)</li>
                  <li>Employer's declaration (werkgeversverklaring)</li>
                  {residencyStatus === 'nonEu' && (
                    <li>Statement of intent from employer for contract extension</li>
                  )}
                </ul>
              </li>
              <li style={styles.listItem}>
                <strong>Financial Overview:</strong>
                <ul style={styles.nestedList}>
                  <li>Bank statements (last 3 months)</li>
                  <li>Savings accounts statements</li>
                  <li>Debt/loan statements</li>
                  <li>BKR credit check</li>
                </ul>
              </li>
            </ul>
          </div>

          <div style={styles.timelineSection}>
            <h5 style={styles.timelineTitle}>Stage 2: After Offer Acceptance</h5>
            <p style={styles.timelineDescription}>Documents needed after offer acceptance:</p>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Property Documents:</strong>
                <ul style={styles.nestedList}>
                  <li>Purchase agreement (koopovereenkomst)</li>
                  <li>Valuation report (taxatierapport)</li>
                  <li>Building inspection report (if required)</li>
                  <li>Energy label certificate</li>
                  <li>Ground lease details (if applicable)</li>
                </ul>
              </li>
              <li style={styles.listItem}>
                <strong>Insurance:</strong>
                <ul style={styles.nestedList}>
                  <li>Home insurance quote</li>
                  <li>Life insurance application (if required)</li>
                </ul>
              </li>
            </ul>
          </div>

          <div style={styles.timelineSection}>
            <h5 style={styles.timelineTitle}>Stage 3: Final Mortgage Agreement</h5>
            <p style={styles.timelineDescription}>Final documents for notary:</p>
            <ul style={styles.list}>
              <li style={styles.listItem}>
                <strong>Final Requirements:</strong>
                <ul style={styles.nestedList}>
                  <li>Bank guarantee letter</li>
                  <li>Proof of insurance policies</li>
                  <li>Final mortgage offer acceptance</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </CollapsibleSection>

      {/* Timeline Section */}
      <CollapsibleSection
        title="3. Process Timeline & Costs"
        isOpen={openSections.process}
        onToggle={() => toggleSection('process')}
      >
        <div style={styles.subsection}>
          <h4 style={styles.subsectionTitle}>Typical Timeline</h4>
          <div style={styles.timeline}>
            {residencyStatus === 'eu' ? (
              // EU Timeline
              <>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 1-2</span>
                  <strong>Initial Registration</strong>
                  <p>Municipality registration (BSN), bank account setup</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 2-3</span>
                  <strong>Document Collection</strong>
                  <p>Employment contract, payslips, bank statements</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 3-4</span>
                  <strong>Initial Mortgage Approval</strong>
                  <p>Financial assessment and maximum mortgage calculation</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Variable</span>
                  <strong>House Hunting</strong>
                  <p>Property search and bidding process</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 1-2 after acceptance</span>
                  <strong>Property Documentation</strong>
                  <p>Valuation, inspection, final approvals</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 4-6 after acceptance</span>
                  <strong>Completion</strong>
                  <p>Final mortgage approval and notary signing</p>
                </div>
              </>
            ) : (
              // Non-EU Timeline
              <>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 1-6</span>
                  <strong>Residence Permit Processing</strong>
                  <p>Obtaining necessary permits and registrations</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 6-8</span>
                  <strong>Initial Registration</strong>
                  <p>Municipality registration (BSN), bank account setup</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 8-12</span>
                  <strong>Document Collection & Verification</strong>
                  <p>Employment verification, document translations, additional legal checks</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 12-16</span>
                  <strong>Initial Mortgage Approval</strong>
                  <p>Extended financial assessment, additional background checks</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Variable</span>
                  <strong>House Hunting</strong>
                  <p>Property search and bidding process</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 2-4 after acceptance</span>
                  <strong>Property Documentation</strong>
                  <p>Valuation, inspection, legal verifications</p>
                </div>
                <div style={styles.timelineItem}>
                  <span style={styles.timelineDuration}>Week 8-12 after acceptance</span>
                  <strong>Completion</strong>
                  <p>Extended checks, final mortgage approval, notary signing</p>
                </div>
              </>
            )}
        </div>

          <div style={styles.timelineNote}>
            <p style={styles.note}>
              {getContent(
                "Average total time from registration to completion: 3-4 months",
                "Average total time from residence permit to completion: 6-8 months"
              )}
            </p>
            <p style={styles.note}>
              {getContent(
                "Timeline can vary based on individual circumstances and bank requirements",
                "Additional time may be needed for document verification and legal checks"
              )}
            </p>
          </div>
        </div>

        <div style={styles.subsection}>
          <h4 style={styles.subsectionTitle}>Associated Costs</h4>
          <ul style={styles.list}>
            <li style={styles.listItem}>
              <strong>Registration Costs:</strong>
              <ul style={styles.nestedList}>
                <li>Municipal registration: €50-100</li>
                <li>Document translations: €40-60 per page</li>
                <li>Document apostille: €20-30 per document</li>
                {residencyStatus === 'nonEu' && (
                  <li>Residence permit: €210</li>
                )}
              </ul>
            </li>
            <li style={styles.listItem}>
              <strong>Property Purchase Costs:</strong>
              <ul style={styles.nestedList}>
                <li>Transfer tax: 2% of property value</li>
                <li>Valuation report: €500-700</li>
                <li>Building inspection: €400-600</li>
                <li>Notary fees: €1,500-2,500</li>
                <li>Mortgage advisor: €2,000-3,000</li>
                {residencyStatus === 'nonEu' && (
                  <li>Additional legal verification: €300-500</li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </CollapsibleSection>
    </div>
  );
};

export default Guide;