import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

// We'll expand this as we add more countries
const countries = [
  {
    code: 'NL',
    name: 'Netherlands',
    flag: '🇳🇱',
    config: {
      transferTaxRate: 0.02,
      mortgageYears: [10, 15, 20, 25, 30],
      baseMultiplier: 4.5,
      highIncomeThreshold: 80000,
      highIncomeMultiplier: 5.0,
      employmentTypes: [
        { value: 'permanent', label: 'Permanent Contract', reduction: 0 },
        { value: 'temporary', label: 'Temporary Contract', reduction: 0.1 },
        { value: 'self-employed', label: 'Self Employed', reduction: 0.2 }
      ],
      residencyRules: {
        lessThan3Months: 0.7,
        lessThan6Months: 0.8,
        lessThan12Months: 0.9,
        established: 1.0
      },
      additionalCosts: {
        notaryFees: 2000,
        advisorFees: 2500,
        bankFees: 750
      }
    }
  }
];

const CountrySelector = ({ selectedCountry, setSelectedCountry }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCountries = countries.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const styles = {
    container: {
      margin: '0 auto',
      maxWidth: '1200px',
      padding: '0px 16px'
    },
    selectorWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px'
    },
    label: {
      color: '#64748b',
      fontSize: '16px'
    },
    selectButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px 16px',
      backgroundColor: '#ffffff',
      border: '1px solid #e2e8f0',
      borderRadius: '12px',
      width: '240px',
      cursor: 'pointer',
      fontSize: '16px',
      color: '#334155'
    },
    selectedContent: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    flag: {
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center'
    },
    dropdownContainer: {
      position: 'absolute',
      top: 'calc(100% + 4px)',
      backgroundColor: '#ffffff',
      border: '1px solid #e2e8f0',
      borderRadius: '12px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.05)',
      width: '240px',
      overflow: 'hidden'
    },
    searchInput: {
      width: '100%',
      padding: '12px 16px',
      border: 'none',
      borderBottom: '1px solid #e2e8f0',
      fontSize: '16px',
      outline: 'none'
    },
    countryList: {
      maxHeight: '200px',
      overflowY: 'auto'
    },
    countryOption: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 16px',
      cursor: 'pointer',
      fontSize: '16px',
      gap: '8px',
      width: '100%'
    },
    noResults: {
      padding: '10px 16px',
      textAlign: 'center',
      color: '#64748b',
      fontSize: '14px'
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.selectorWrapper}>
        <div style={{ position: 'relative' }} ref={dropdownRef}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            style={styles.selectButton}
          >
            <span style={styles.selectedContent}>
              <span style={styles.flag}>{selectedCountry?.flag}</span>
              {selectedCountry?.name || "Select country..."}
            </span>
            <ChevronDown 
              size={20} 
              color="#64748b"
              style={{
                transform: isOpen ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.2s ease'
              }}
            />
          </button>

          {isOpen && (
            <div style={styles.dropdownContainer}>
              <input
                type="text"
                placeholder="Search country..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={styles.searchInput}
                autoFocus
              />
              <div style={styles.countryList}>
                {filteredCountries.map((country) => (
                  <div
                    key={country.code}
                    style={styles.countryOption}
                    onClick={() => {
                      setSelectedCountry(country);
                      setIsOpen(false);
                      setSearchTerm('');
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#f8fafc';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    <span style={styles.flag}>{country.flag}</span>
                    {country.name}
                  </div>
                ))}
                {filteredCountries.length === 0 && (
                  <div style={styles.noResults}>No countries found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountrySelector;